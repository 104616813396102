import React from 'react';

import ServicesLayout from '../../components/ServicesLayout';
import EnrollmentImage from '../../images/image_onlineEnrollment.jpg';

const Enrollment = () => (
  <ServicesLayout title="Easy Benefit Administration" bannerTitle="Easy Benefit Administration" bannerCopy="Easy to Use on-line enrollment capability for an unlimited number of benefit plans.">
    <h2 className="inner-page__heading">Online Enrollment &amp; Benefit Admin Services</h2>
    <p className="inner-page__lead">Seamlessly integrated, online enrollment for all of your benefit plans across multiple insurers and payroll processors.</p>
    <img className="inner-page__hero" src={EnrollmentImage} alt="Woman on phone in office setting enrolling online" />
    <p>Employees enjoy the ability to easily review plan summaries and/or comparisons and enroll in benefit plans on their computer, tablet or smartphone – securely, and at their convenience.  Employers get the simplicity of a single online interface for managing all benefit plans with customized reporting and role-based data access. No matter how many employees, insurers or payroll processors you need to manage, Tri-Star pulls it together into one simple system.</p>
    <p>Tri-Star Service Highlights:</p>
    <ul>
      <li>Online enrollment for an unlimited number of benefit plans. Tri-Star can administer an unlimited number of plans with various combinations of options and tiers.</li>
      <li>Seamless integration with multiple insurance carriers and payroll processors in their preferred format.</li>
      <li>Real-time access to enrollment data and customized reports for HR personnel with role-based security.</li>
      <li>100% online enrollment, customized paper forms for offline enrollment, or  combination of the two.</li>
    </ul>
    <p className="inner-page__lead">How can Tri-Star help administer your employee benefits? Call us at <a href="tel:3145764022">314.576.4022</a> or email us at <a href="mailto:info@tri-starsystems.com">info@tri-starsystems.com</a>.</p>
  </ServicesLayout>
);

export default Enrollment
